import React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { connect } from 'react-redux';

import FormattedDateNoTimeZone from 'components/Shared/FormattedDateNoTimeZone/FormattedDateNoTimeZone';
import LabeledCheckbox from 'components/Shared/Inputs/LabeledCheckbox';
import { getFormattedAmountWithoutTax, getIntervalSentence } from 'components/Subscription/utils';
import SegmentIO from 'reporting/SegmentIO';
import { getLegalLinks } from 'shared/clientUtils';
import { formatDateMMDDYYWithoutZeros } from 'shared/dates';
import { lowercaseFirstLetter } from 'shared/utils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { PAYMENT_REJECT_TYPES } from 'store/payment/helpers';
import { setIsSavePaymentMethodChecked } from 'store/payment/slice';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';
import { colors, fontSize } from 'styles/cp';
import { FeatureFlags } from 'types/FeatureFlags';

export interface Props {
  startDate: string;
  endDate?: string;
  companyName: string;
  amount: number;
  currency: string;
  isSavePaymentMethodChecked: boolean;
  paymentRejectType: string;
  setIsSavePaymentMethodChecked: (isSavePaymentMethodChecked: boolean) => void;
  featureFlags: FeatureFlags;
  taxAmount: number | null;
  frequency?: string;
  frequencyInterval?: number;
  daysOfWeek?: string[];
  monthIndex?: number;
  daysOfMonth?: number[];
  weekIndex?: number;
}

const SubscriptionConsent: React.FC<Props> = ({
  companyName,
  amount,
  startDate,
  endDate,
  currency,
  isSavePaymentMethodChecked,
  paymentRejectType,
  setIsSavePaymentMethodChecked,
  featureFlags,
  taxAmount,
  frequency,
  frequencyInterval,
  daysOfWeek,
  monthIndex,
  daysOfMonth,
  weekIndex,
}) => {
  const onChange = () => {
    const newValue = !isSavePaymentMethodChecked;
    SegmentIO.transactionEngaged({
      ui_object: 'checkbox',
      ui_object_detail: 'terms',
      ui_action: newValue ? 'enabled' : 'disabled',
      ui_access_point: 'transaction_flow',
    });
    setIsSavePaymentMethodChecked(newValue);
  };

  const style = {
    fontSize: fontSize.xxs,
    color: colors.darkGray,
  };
  const formattedAmountElement = (
    <FormattedNumber value={amount} style="currency" currency={currency} />
  );
  const intl = useIntl();
  const { privacyUrl, tosUrl } = getLegalLinks(intl.locale);
  const isSubscriptionV1 = featureFlags && featureFlags['cp-subscriptions-v1'];

  const consentDefaultMessage = `<b>By selecting Agree and pay, I authorize {companyName} to charge the payment method listed above on {startDate} and {interval} thereafter at {pretaxAmount}, plus tax, ${
    endDate ? `until {endDate}` : ''
  } unless I cancel. I can cancel anytime by clicking on the manage recurring payment link in the emails.</b> I agree to the <tosLink>Terms of Service</tosLink> and that I've read and acknowledged Intuit's <privacyLink>Global Privacy Statement</privacyLink>. You also provide written instructions for Intuit Inc. to obtain and periodically refresh information from bank verification data providers to validate your bank account information and for other fraud detection purposes.`;

  return (
    <div className="subscription-consent-cta">
      {isSubscriptionV1 ? (
        <LabeledCheckbox
          id="cta"
          checked={isSavePaymentMethodChecked}
          onChange={onChange}
          error={paymentRejectType === PAYMENT_REJECT_TYPES.MISSING_CONSENT}
          label={
            <span style={style} id="cta-description">
              <FormattedMessage
                id={endDate ? 'SUBSCRIPTION_CONSENT_V1_WITH_END_DATE' : 'SUBSCRIPTION_CONSENT_V1'}
                defaultMessage={consentDefaultMessage}
                values={{
                  companyName,
                  startDate: formatDateMMDDYYWithoutZeros(startDate),
                  interval: lowercaseFirstLetter(
                    getIntervalSentence({
                      frequency,
                      frequencyInterval,
                      daysOfWeek,
                      monthIndex,
                      daysOfMonth,
                      weekIndex,
                    })
                  ),
                  endDate: endDate ? formatDateMMDDYYWithoutZeros(endDate) : '',
                  pretaxAmount: getFormattedAmountWithoutTax(amount, currency, taxAmount),
                  b: (chunks: string) => <span className="bold-text">{chunks}</span>,
                  tosLink: (chunks: string) => (
                    <a className="link" href={tosUrl}>
                      {chunks}
                    </a>
                  ),
                  privacyLink: (chunks: string) => (
                    <a className="link" href={privacyUrl}>
                      {chunks}
                    </a>
                  ),
                }}
              />
            </span>
          }
        />
      ) : (
        <LabeledCheckbox
          id="cta"
          checked={isSavePaymentMethodChecked}
          onChange={onChange}
          error={paymentRejectType === PAYMENT_REJECT_TYPES.MISSING_CONSENT}
          label={
            <span style={style} id="cta-description">
              <FormattedMessage
                id="SUBSCRIPTION_CONSENT"
                defaultMessage={`I understand that I am purchasing a subscription and authorize ${companyName} to charge the payment method listed above on ${(
                  <FormattedDateNoTimeZone value={startDate} />
                )} thereafter at ${amount}, plus tax, unless I cancel. I can cancel anytime by clicking on the manage my subscription link in the subscription emails.`}
                values={{
                  companyName,
                  amount: formattedAmountElement,
                  startDate: <FormattedDateNoTimeZone value={startDate} />,
                }}
              />
            </span>
          }
        />
      )}

      <style jsx>{`
        .subscription-consent-cta {
          padding: 16px 0;
        }

        .bold-text {
          font-family: AvenirNextforINTUIT-Demi;
        }

        .link {
          color: ${colors.blue05};
          text-decoration: none;
        }
      `}</style>
    </div>
  );
};

function mapStateToProps(store: RootState) {
  const { payment, sale, companyInfo } = store;
  const {
    startDate,
    endDate,
    frequency,
    frequencyInterval,
    daysOfWeek,
    monthIndex,
    daysOfMonth,
    weekIndex,
  } = saleSelectors.subscriptionInfo(sale) || {};

  return {
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    currency: saleSelectors.currencySelector(sale),
    amount: saleSelectors.amountSelector(sale),
    startDate,
    endDate,
    frequency,
    frequencyInterval,
    daysOfWeek,
    monthIndex,
    daysOfMonth,
    weekIndex,
    isSavePaymentMethodChecked: payment.isSavePaymentMethodChecked,
    paymentRejectType: payment.paymentRejectType,
    taxAmount: saleSelectors.taxAmountSelector(sale),
  };
}

export default connect(mapStateToProps, {
  setIsSavePaymentMethodChecked,
  // @ts-ignore
})(SubscriptionConsent);

export { SubscriptionConsent };
